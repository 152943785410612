import { useActivePopups } from '@app/popups';
import { Popup } from '@components/Molecules/Popup';
import { CategoryItem } from '@interfaces';
import { Page, SearchFieldVariantEnum } from '@teddly/teddly-ui-components';
import useWindowDimensions from '@hooks/useWindowDimension';
import { SearchBar } from '@components/Molecules/Search';
import styles from './HomePage.module.scss';
import classNames from 'classnames';
import DeliveryInHeader from '../Checkout/components/Delivery/DeliveryInHeader';
import React, { useState } from 'react';
import { useAuth } from 'teddly-sdk';
import { useHomePageContext } from '@context/HomePageContext';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import HomePageSections from './HomePageSections';
import { useChannelContext } from '@context/ChannelContext';
import { HomePageIDs } from '@cypress/components/pages/Home.cy'

type Props = {
  rootCategories?: CategoryItem[];
};

function Home({ rootCategories }: Props): JSX.Element {
  const [activePopup, closePopup] = useActivePopups('home');
  const { setStateType, stateType, bodyFooterInformationProps } =
    useHomePageContext();
  const { user } = useAuth();

  const testIds = new HomePageIDs();

  const { isChannelSelected } = useChannelContext();

  const { isLargeTablet } = useWindowDimensions();

  const [marginByBackground, setMarginByBackground] = useState<number>(null);
  const [screenSize, setScreenSize] = useState<number>(null);

  React.useEffect(() => {
    const muchParent = window.getComputedStyle(
      document.getElementById('pageBodyGridContainer'),
    );
    const homePageSection = window.getComputedStyle(
      document.getElementById('home-page-section'),
    );
    const muchParentWidth = parseFloat(muchParent.width);
    const homePageSectionWidth = parseFloat(homePageSection.width);
    setScreenSize(muchParentWidth);
    setMarginByBackground((muchParentWidth - homePageSectionWidth) / 2);
  }, [window.innerWidth, window.innerHeight]);

  const selectStateTypeButtons = null;
  // <div style={{ gap: "12px", display: "flex" }}>
  //   <Chip
  //     label={'Explore'}
  //     checked={stateType === HomePageStateType.EXPLORE && !isLoading}
  //     leadingIcon={<Compass />}
  //     disabled={isLoading}
  //     loading={isLoading}
  //     onClick={() => setStateType(HomePageStateType.EXPLORE)}
  //   />
  //   <Chip
  //     label={'Deals'}
  //     checked={stateType === HomePageStateType.DEALS && !isLoading}
  //     leadingIcon={<Award />}
  //     disabled={isLoading}
  //     loading={isLoading}
  //     onClick={() => setStateType(HomePageStateType.DEALS)}
  //   />
  // </div>

  return (
    <Page.Body
      {...bodyFooterInformationProps}
      className={styles.test2}
      isWithHeader={true}
      isWithBottomNav={isLargeTablet}
      isWithFooterButton={false}
      hiddenScrollbar
      isWithBackgroundColor
      isTopGray
      isWithNavbar={isChannelSelected}
      pageBodyGridContainerClassName={classNames(
        styles.pageBodyGridContainerClassName,
        isLargeTablet && styles.isDense,
      )}
      search={
        <SearchBar
          key={`home page ${isLargeTablet ? SearchFieldVariantEnum.DENSE : SearchFieldVariantEnum.LARGE}`}
          variant={
            isLargeTablet
              ? SearchFieldVariantEnum.DENSE
              : SearchFieldVariantEnum.LARGE
          }
          stretch
          searchButtonTestId={testIds.searchBtnID}
          searchInputTestId={testIds.searchInputID}
          isInHeader={false}
          isWithStrip={true}
          largeVariantPaddingLeftOfBottomComponent={marginByBackground}
          valueInBottomOfSearchBar={
            isLargeTablet ? <DeliveryInHeader show/> : selectStateTypeButtons
          }
        />
      }>
      <>
        <HomePageSections
          key={user?.id || 'anonymous'}
          marginByBackground={marginByBackground}
          screenSize={screenSize}
          selectStateTypeButtons={selectStateTypeButtons}
        />
        {activePopup && activePopup?.body && (
          <Popup
            open={Boolean(activePopup)}
            onClose={closePopup}
            title={activePopup?.title}
            size={activePopup?.boxSize}
            body={activePopup?.body}
          />
        )}
      </>
    </Page.Body>
  );
}

export default Home;
