import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { ICard, ICardIDs } from '../../interfaces/ICard.cy';
import TestObjectBase from '../TestObjectBase';

const { namedElements: elm, components } = utilsData.elementsDataTestIDs;

export class CardIDs implements ICardIDs {
  titleID: string;
  bodyID: string;
  descriptionID: string;

  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${components.cards.title}`;

    this.titleID = TestIDFormat.element(this.fullPrefix, elm.title);
    this.bodyID = TestIDFormat.element(this.fullPrefix, elm.body);
    this.descriptionID = TestIDFormat.element(this.fullPrefix, elm.description);
  }
}

export class Card<T extends CardIDs>
  extends TestObjectBase<T>
  implements ICard
{
  constructor(cardIDs: CardIDs) {
    super(cardIDs);
  }

  get title() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.titleID, timeout);
    };
  }
  get body() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.bodyID, timeout);
    };
  }

  get description() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.descriptionID, timeout);
    };
  }
}
