import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { Page, PageIDs } from './Page.cy';
import { ProductPage } from './ProductPage.cy';

const {
  buttons: btn,
  inputFields: field,
  components,
} = utilsData.elementsDataTestIDs;

export class HomePageIDs extends PageIDs {
  searchInputID: string;
  searchBtnID: string;

  constructor() {
    super(components.pages.home);
    this.searchInputID = TestIDFormat.inputField(this.fullPrefix, field.search);
    this.searchBtnID = TestIDFormat.button(this.fullPrefix, btn.search);
  }
}

export class HomePage extends Page<HomePageIDs> {
  constructor() {
    super(HomePageIDs);
  }

  get searchInputField() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchInputID, timeout);
    };
  }

  get searchBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchBtnID, timeout);
    };
  }

  get searchResultPage() {
    return new ProductPage();
  }
}
