import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { ItemBase, ItemBaseIDs } from '../other-components/ItemBase.cy';
import { CardIDs, Card } from './Card.cy';

const { namedElements: elm, components } = utilsData.elementsDataTestIDs;

export class ItemCardIDs extends CardIDs {
  vendorLogoID: string;
  itemID: ItemBaseIDs;

  constructor(prefix: string) {
    super(`${prefix}-${components.cards.item}`);
    this.vendorLogoID = TestIDFormat.element(this.fullPrefix, elm.vendorLogo);
    this.itemID = new ItemBaseIDs(this.fullPrefix);
  }
}

export class ItemCard extends Card<ItemCardIDs> {
  constructor(itemCardIDs: ItemCardIDs) {
    super(itemCardIDs);
  }
  get vendorLogo() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.vendorLogoID, timeout);
    };
  }

  get item() {
    return new ItemBase(this.testIDs.itemID);
  }
}
