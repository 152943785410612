import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { Page, PageIDs } from './Page.cy';
import { ItemCard, ItemCardIDs } from '../cards/Item.cy';

const {
  buttons: btn,
  namedElements: elm,
  components,
} = utilsData.elementsDataTestIDs;

export class ProductPageIDs extends PageIDs {
  categorySlidebarID: string;
  categoryBtnID: string;
  categoryCloseBtnID: string;
  titleID: string;
  fastDeliveryFilterBtnID: string;
  filterButtonID: string;
  ItemCardIDs: ItemCardIDs;

  constructor() {
    super(components.pages.products);
    this.categorySlidebarID = TestIDFormat.element(
      this.fullPrefix,
      elm.categorySlideBar,
    );
    this.categoryBtnID = TestIDFormat.button(this.fullPrefix, btn.category);
    this.categoryCloseBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.categoryClose,
    );
    this.titleID = TestIDFormat.element(this.fullPrefix, elm.title);
    this.fastDeliveryFilterBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.fastDelivery,
    );
    this.filterButtonID = TestIDFormat.button(this.fullPrefix, btn.filter);
    this.ItemCardIDs = new ItemCardIDs(this.fullPrefix);
  }
}

export class ProductPage extends Page<ProductPageIDs> {
  constructor() {
    super(ProductPageIDs);
  }

  get categorySlidebar() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.categorySlidebarID, timeout);
    };
  }

  get categoryCloseBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.categoryCloseBtnID, timeout);
    };
  }

  get categoryBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.categoryBtnID, timeout);
    };
  }

  get title() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.titleID, timeout);
    };
  }

  get fastDeliveryFilterButton() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.fastDeliveryFilterBtnID, timeout);
    };
  }

  get filterButton() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.filterButtonID, timeout);
    };
  }

  get itemCard() {
    return new ItemCard(this.testIDs.ItemCardIDs);
  }
}
